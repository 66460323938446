.filter {
  padding-top: 18px;
  padding-bottom: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wrapper {
  background: #FFFFFF;
}


.move_to {
  margin-left: 140px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;

  color: #000000;
}

.search_wrapper {
  display: flex;
  flex: 1;
  padding-left: 10px;
}

.search {
  max-width: 500px;
  width: 100%;
  input {
    padding-top: 8.5px;
    padding-bottom: 8.5px;
  }
}

.button {
  &:not(:last-child) {
    margin-right: 8px;
  }
}


