.title {
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  padding-top: 10px;
  margin-bottom: 0;
}
.list {
  flex: 1;
  overflow: auto;
}

.loader_wrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper {
  padding: 0 10px;
  max-width: 600px !important;
  height: calc(100vh - 300px) !important;
}

.button_container {
  width: 100%;
  padding: 4px 20px;
}

.item_wrapper {
  padding: 10px;
  margin: 10px 10px 0 10px;
  position: relative;
  border: 3px solid transparent;
  &.main {
    transition: all .3s;
    border-color: #C5D5FF;
    border-radius: 10px;
    &:after {
      content: 'Main';
      color: #C5D5FF;
      background: #fff;
      top: -10px;
      padding: 0 10px;
      right: 20px;
      position: absolute;
    }
  }
}

.field {
  margin-bottom: 4px;
}

