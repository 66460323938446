@import-normalize;

* {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
}

body {
   font-family: "Roboto", serif;
   position: relative;
}

:root {
   --regular-color: #2D4170;
   --secondary-accent-color: #81bb41;
   --regular-bg: #e7efff;
   --regular-bg-darken: #E0E0E0;
   --regular-bg-darker: #B6B6B6;
}

html, body, #root {
   min-height: 100vh;
   height: 100%;
}

.container {
   max-width: 1170px;
   width: 100%;
   padding: 0 15px;
   margin: auto;
}

.login-page {
   height: 100%;
   background: url("/assets/login-bg.png") no-repeat;
   background-size: cover;

   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   border-radius: 10px;
   align-content: center;

   h1 {
      font-style: normal;
      font-weight: bold;
      font-size: 30px;
      line-height: 35px;
      text-align: center;
      color: #2D4170;
      margin-bottom: 36px;
      margin-top: 0;
   }
}

.app {
   height: 100%;
   display: flex;
   flex-direction: column;
}


.floating-button {
   position: fixed;
   right: 20px;
   bottom: 20px;
   &-text {
      font-size: 16px;
   }
}

   @mixin custom-scrollbar {
      &::-webkit-scrollbar {

         width: 6px;
         position: absolute;
         right: -10px;
      }
      &::-webkit-scrollbar-track {

         background: #82828233;
         border-radius: 4px;
      }

      &::-webkit-scrollbar-thumb {
         background: #6b6b6b;
         border-radius: 4px;
      }
      &::-webkit-scrollbar-button {
         height: 16px;
      }

      &::-webkit-scrollbar-thumb:hover {
         background: rgba(85, 85, 85, 0.671);
      }

   }


// tooltip
.MuiTooltip-popper {
   z-index: 10000 !important;
}

// tostify
.Toastify__toast--success {
   background: var(--regular-color);
   font-size: 14px;
}
.picker-wrapper {
   .MuiFormControl-root {
      width: 100%;
   }
}


.MuiInputLabel-outlined.MuiInputLabel-shrink {
   background: #fff;
   font-size: 14px;
}

.MuiSkeleton-root {
   background-color: #fff;
   border-radius: 4px;
}

.modal-wrapper {
   position: relative;
   max-width: 400px;
   overflow: auto;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   outline: none;
   height: 500px;
   background: white;
   box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
   border-radius: 4px;
   display: flex;
   flex-direction: column;
}

.close_button {
   position: sticky;
   padding: 4px;
   top: 12px;
   left: 100%;
   z-index: 10;
   width: 22px;
   height: 22px;
   border: none;
   border-radius: 4px;
   color: #333333;
   font-weight: bold;
   background: transparent;
   cursor: pointer;
   transition: all .3s;
   background: var(--regular-bg);
   &:hover {
      background: var(--regular-bg);
   }
}
