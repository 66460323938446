.block {
  background: var(--regular-bg);
  padding: 6px 0;
  min-height: 40px;
  display: flex;
  align-items: center;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.items_list {
  display: flex;
}

.item {
  background: var(--regular-bg-darken);
  border-radius: 4px;
  padding: 7px 11px;
  font-size: 12px;
  line-height: 14px;
  color: #333333;
  align-items: center;
  display: flex;

  &:not(:last-child) {
    margin-right: 6px;
  }

  svg {
    width: 10px;
    margin-left: 6px;
  }
  transition: all .3s;
  &:hover {
    cursor: pointer;
    background: var(--regular-bg-darker);

  }
}

.total {
  font-size: 14px;
}