@import '../../index';

.wrapper {
  @include custom-scrollbar;
  background: transparent;
  position: relative;
  width: 400px;
  overflow: auto;
  padding: 0 4px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  max-height: 620px;
}



.modal {
  padding: 12px 16px;
  min-height: 600px;
  position: relative;
  background: white;
  outline: none;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.row {
  display: flex;
  .field:not(:last-child) {
    margin-right: 4px;
  }
}

.field {
  width: 100%;
  display: flex;
  margin-top: 12px;
  flex-direction: column;
}

.title {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #000000;
  padding-bottom: 10px;
}

.button_wrapper {
  width: 390px;
  top: calc(320px + 50%);
  left: 50%;
  transform: translate(-50.5%, -50%);
  outline: none;
  position: fixed;
}

