@import "../index";

.modal {
  z-index: 10000;
  background: #FFFFFF;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding-bottom: 15px;
  width: 300px;
  position: absolute;
}

.title {
  padding-top: 20px;
  text-transform: unset;
  margin-right: 20px;
  margin-left: 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #000000;
  border-radius: 4px;
  transition: all 300ms;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;
  &::selection {
    color: inherit;
  }

}

.field_title {
  position: relative;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  padding-bottom: 10px;
}

.handle {
  cursor: move;
  width: 100%;
}

.vessels_list {
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  @include custom-scrollbar;
}

.content {
  display: flex;
  flex-direction: column;
  padding: 20px 0 0 0;
  cursor: default;
  max-height: 500px;
  min-height: 200px;

  .button {
    padding: 6.5px 10px;
    margin-left: 12px;
  }

  .field {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    b {
      padding-right: 10px;
      text-transform: capitalize;
      font-weight: 500;
    }
    &:not(:last-child) {
      padding-bottom: 4px;
    }
  }
  
  .field_text {
    text-align: right;
  }
  
}


.vessel_info {
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #d9d9d9;
}

.vessel_photo {
  width: 100%;
  height: 100%;
}

.vessel_info_footer {
  padding-top: 10px;
  display: flex;
  justify-content: center;
}

.zero_ui {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: var(--regular-bg-darker);
}

.form {
  padding: 0 15px;
  display: flex;
  align-items: flex-start;
}

.open_button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: none;
  cursor: pointer;
  padding: 6px 4px;
  transition: all .3s;
  border-radius: 4px;
  svg {
    transition: all .3s;
  }
  &:hover {
    background: #e2e2e2;
  }
}

.total {
  padding: 5px 15px 5px 20px;
  font-size: 14px;
  color: grey;
  box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
}