.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 22px 16px;
}

.footer_wrapper {
  background: #F2F2F2;

}

.list {
  padding: 22px 16px;
  flex-grow: 1;
  &_item {
    color: #000000;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 4px;

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    cursor: pointer;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;

    transition: all .3s;
    &:hover {
      background: #F0F4FF;
    }
  }
}

.status_name {
  padding: 14px 0;
}

.color {
  border-radius: 4px;
  height: 40px;
  width: 40px;
}

.row {
  display: flex;
  align-items: flex-start;
  .field {
    margin-top: 0;
    flex: 1;
    margin-right: 18px;
  }
}

.field {
  width: 100%;
  display: flex;
  margin-top: 24px;
  justify-content: space-between;
  align-items: center;
}


.field_save {
  composes: field;
  margin-top: 14px;
}

.field_delete {
  composes: field;
  margin-top: 4px;
}

.form {
  flex: 1;
  padding: 22px 16px;
}