.header {
  padding: 10px 0;
  background: var(--regular-bg);

}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

